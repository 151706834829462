import { fetch } from '../services/fetch'
import { toParams } from '../services/utils'
import { ERROR, setMessage, SUCCESS } from '../modules/messages'
import { push } from 'connected-react-router'
import { CONSOLIDATED_ACCOUNT } from "./account";

export const FETCH_SUMMARY_PRODUCTS = 'summary-products/FETCH_SUMMARY_PRODUCTS'
export const FETCH_CLIENT_PRODUCTS = 'products/FETCH_CLIENT_PRODUCTS'
export const FETCH_STORE_PRODUCTS = 'products/FETCH_STORE_PRODUCTS'
export const REQUEST_PRODUCT = 'products/REQUEST_PRODUCT'
export const SET_PRODUCT_STATUS = 'products/SET_PRODUCT_STATUS'
export const CHOOSE_PRODUCT = 'products/CHOOSE_PRODUCT'
export const CHOOSE_PRODUCT_TYPE = 'products/CHOOSE_PRODUCT_TYPE'
export const FETCH_PRODUCT_TYPES = 'products/FETCH_PRODUCT_TYPES'
export const FETCH_PRODUCT_BY_PROMOCODE = 'products/FETCH_PRODUCT_BY_PROMOCODE'
export const PRODUCT_FORM = 'products/PRODUCT_FORM'

export function productForm (data) {
  return dispatch =>
    dispatch({
      type: PRODUCT_FORM,
      payload: data,
    })
}

export function chooseProduct (productId, nextPage) {
  return dispatch => {
    dispatch(push(nextPage))

    return setTimeout(() => dispatch({
      type: CHOOSE_PRODUCT,
      payload: { productId },
    }), 1000)
  }
}

export function chooseProductType (productTypeId, nextPage) {
  return dispatch => {
    dispatch(push(nextPage))

    return setTimeout(() => dispatch({
      type: CHOOSE_PRODUCT_TYPE,
      payload: { productTypeId },
    }), 1000)
  }
}

export function fetchSummaryProducts (accountId, params = {}) {
  const query = toParams(params)
  const url = accountId === CONSOLIDATED_ACCOUNT.id ? `/products-summary${query}` : `/accounts/${accountId}/products-summary${query}`
  const request = fetch(url).then(res => res.json())
  return {
    type: FETCH_SUMMARY_PRODUCTS,
    payload: request,
  }
}

export function fetchProductByPromoCode (promoCodeId) {
  const url = `/promocodes/${promoCodeId}`
  const request = fetch(url).then(res => res.json())
  return {
    type: FETCH_PRODUCT_BY_PROMOCODE,
    payload: request,
  }
}


export function fetchProducts (accountId, params = {}, hasSummary) {
  return dispatch => {
    const query = toParams(params)

    const url = accountId === CONSOLIDATED_ACCOUNT.id
      ? `/products${query}`
      : `/accounts/${accountId}/products${query}`

    const request = fetch(url)
      .then(res => res.json())
      .then(hasSummary &&
        dispatch(fetchSummaryProducts(accountId)))

    return dispatch({
      type: FETCH_CLIENT_PRODUCTS,
      payload: request,
      meta: { renew: !!params.product },
    })
  }
}

export function setProductStatus (id, productStatus, observation = '', contractId, justification) {
  return dispatch => {
    const request = fetch(`/products/${id}`, {
      method: 'PUT',
      body: JSON.stringify({ status: productStatus, observation, contract_id: contractId, justification }),
    })
      .then(res => res.json())
      .then(res => {
        const { status, product_type: { name } } = res
        dispatch(setMessage(SUCCESS, `Produto "${name}" alterado para (${status})`))
        return dispatch(push('/store/products'))
      })
      .catch(() => {
        dispatch(setMessage(ERROR, `Ocorreu um erro`))

        return ({ id: false })
      })

    // return dispatch({
    //   type: SET_PRODUCT_STATUS,
    //   payload: request,
    // })
  }
}

export function fetchStoreProducts (store) {
  const request = fetch(`/stores/${store}`).then(res => res.json())
  return {
    type: FETCH_STORE_PRODUCTS,
    payload: request,
  }
}

export function fetchProductTypes () {
  const request = fetch('/product-types').then(res => res.json())
  return {
    type: FETCH_PRODUCT_TYPES,
    payload: request,
  }
}

export function requestProduct (accountId, data, autoApprove) {
  return dispatch => {
    const request = fetch(`/accounts/${accountId}/products`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(res => {
        if (autoApprove) {
          dispatch(setMessage(SUCCESS, `Produto '${res.product_type.name}' aprovado automaticamente`))
          return dispatch(push('/store/products'))
        }

        dispatch(push('/store/success'))
        return res
      })
      .catch(() => {
        dispatch(setMessage(ERROR, 'Ocorreu um erro'))
      })

    return dispatch({
      type: REQUEST_PRODUCT,
      payload: request,
    })
  }
}
