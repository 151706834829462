import React, { useState } from 'react';

import InputMask from 'react-input-mask';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Alert, Box, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { create } from '../../actions/users';

import { Button, Choices, Icon } from '../../components';
import Success from './Success';
import _ from 'lodash';


const personsOptions = [
    { label: 'Pessoa Física', value: 'pf' },
    { label: 'Pessoa Jurídica', value: 'pj' },
];

const personPosition = [
    { id: 'Selecione', description: 'Selecione' },
    { id: 'Comercial/Marketing', description: 'Comercial/Marketing' },
    { id: 'Técnico', description: 'Técnico' },
    { id: 'Financeiro', description: 'Financeiro' },
    { id: 'Proprietário', description: 'Proprietário' },
    { id: 'Outro', description: 'Outro' },
];

export default function FormSignup () {
    const { handleSubmit, formState: { errors }, control, setValue } = useForm();

    const dispatch = useDispatch();

    const data = useSelector(state => state.user);

    const [personType, setPersonType] = useState('pf');

    const handleSubmitForm = (formData, event) => {
        event.preventDefault();
        dispatch(create(personType, formData));
    };

    const handleChangeUserType = option => {
        setPersonType(option);
        setValue('person', option);
    };

    return (
        data.email
            ? <Success firstName={data.first_name} email={data.email} />
            : <form name="form" onSubmit={handleSubmit(handleSubmitForm)}>
                <Typography variant='h3' sx={{ textAlign: "center" }}>Crie sua conta</Typography>
                <Alert icon={false} severity="success">
                    <Typography sx={{ textAlign: "justify" }}>
                        Este portal é exclusivo para acesso a serviços e produtos. Para reclamações ou denúncias, <strong><a href='https://www.siteblindado.com/consumidor/denuncie' target='_blank'>clique aqui</a></strong> e acesse o canal adequado.
                    </Typography>
                </Alert>
                <div className="field" />
                <Controller
                    render={({ field }) =>
                        <Choices
                            {...field}
                            items={personsOptions}
                            itemChecked={personType}
                            onClick={handleChangeUserType}
                        />
                    }
                    defaultValue={personType}
                    name="person"
                    control={control}
                />
                <div className="field" />

                <div className="field">
                    <Controller
                        render={({ field }) =>
                            <input
                                {...field}
                                className={`input-lg ${errors.first_name ? 'input-error' : ''}`}
                                type="text"
                                placeholder="Nome *"
                            />
                        }
                        name="first_name"
                        rules={{ required: true }}
                        control={control}
                    />
                </div>

                <div className="field">
                    <Controller
                        render={({ field }) =>
                            <input
                                {...field}
                                className={`input-lg ${errors.last_name ? 'input-error' : ''}`}
                                type="text"
                                placeholder="Sobrenome *"
                            />
                        }
                        name="last_name"
                        rules={{ required: true }}
                        control={control}
                    />
                </div>

                <div className="field">
                    <Controller
                        render={({ field }) =>
                            <input
                                {...field}
                                className={`input-lg ${errors.email ? 'input-error' : ''}`}
                                type="text"
                                placeholder="E-mail *"
                            />
                        }
                        name="email"
                        rules={{ required: true }}
                        control={control}
                    />
                </div>

                <div className="field">
                    <Controller
                        render={({ field }) =>
                            <InputMask
                                {...field}
                                className={`input-lg ${errors.phone ? 'input-error' : ''}`}
                                type="text"
                                mask="(99) 99999-9999"
                                placeholder="Telefone *"
                            />
                        }
                        name="phone"
                        rules={{ required: true }}
                        control={control}
                    />
                </div>

                <div className="field">
                    <Controller
                        render={({ field }) =>
                            <InputMask
                                {...field}
                                className={`input-lg ${errors.cpf ? 'input-error' : ''}`}
                                type="text"
                                mask="999.999.999-99"
                                placeholder="CPF *"
                            />
                        }
                        name="cpf"
                        rules={{ required: true }}
                        control={control}
                    />
                </div>

                {personType === 'pj' &&
                    <div className="pj">
                        <div className="field">
                            <Controller
                                render={({ field }) =>
                                    <input
                                        {...field}
                                        className={`input-lg ${errors.legal_name ? 'input-error' : ''}`}
                                        type="text"
                                        placeholder="Razão Social *"
                                    />
                                }
                                name="legal_name"
                                rules={{ required: true }}
                                control={control}
                            />
                        </div>

                        <div className="field">
                            <Controller
                                render={({ field }) =>
                                    <InputMask
                                        {...field}
                                        className={`input-lg ${errors.cnpj ? 'input-error' : ''}`}
                                        type="text"
                                        placeholder="CNPJ *"
                                        mask="99.999.999/9999-99"
                                    />
                                }
                                name="cnpj"
                                rules={{ required: true }}
                                control={control}
                            />
                        </div>

                        <div className="field" style={{ display: 'flex', flexDirection: 'column', color: 'gray' }}>
                            <label htmlFor="profile">Cargo: </label>
                            <Controller
                                render={({ field }) =>
                                    <select {...field} className={`combo ${errors.profile ? 'input-error' : ''}`}>
                                        {personPosition.map(item =>
                                            <option key={item.id} value={item.id}>
                                                {item.description}
                                            </option>
                                        )}
                                    </select>
                                }
                                name="profile"
                                rules={{ required: true }}
                                control={control}
                            />
                        </div>
                    </div>
                }

                <div className="buttons">
                    <Button>
                        Cadastrar
                    </Button>
                </div>

                {!_.isEmpty(errors) &&
                    <span className="text-alert">
                        Todos os campos são obrigatórios
                    </span>
                }

                <footer>
                    <Link to="/login" className="link-icon">
                        <Icon name="arrow_back" />
                        <span>Voltar para login</span>
                    </Link>
                </footer>
            </form>
    );
}
